import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { accent, primaryFont, secondaryFont, black } from 'constants/theme';

export const Container = styled.div`
  padding: 0 4rem;
  margin: 0 auto;
`;

export const TitleContainer = styled.div`
  max-width: 720px;
  margin: 0 auto;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 4rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 2rem;
  font-family: ${primaryFont};
  color: ${black};
`;

export const ContentBox = styled.div`
  max-width: 960px;
  margin: 0 auto;
`;

export const Main = styled.main``;

export const LinkList = styled.ul``;

export const LinkListItem = styled.li`
  margin: 1.5rem 0;
  border: 1px solid #f5f5f5;
  padding: 2rem 1.5rem;
  text-align: center;

  &:hover {
    background-color: #f5f5f5;
  }
`;
