import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';
import LinkTitle from 'components/title';
import { Title, Main, LinkList, LinkListItem } from 'components/common';
import { Link } from 'gatsby';

const About = ({ data }) => {
  const {
    ofertaJson: { services, title, content },
  } = data;
  return (
    <Layout>
      <Head pageTitle={title} />
      <Box>
        <Main>
          <Title as="h2">{title}</Title>
          <div
            dangerouslySetInnerHTML={{
              __html: content.childMarkdownRemark.html,
            }}
          />
          <LinkList>
            {services.map((service, index) => {
              return (
                <Link key={index} to={service.url}>
                  <LinkListItem>
                    <LinkTitle as="h3" isBlack={true}>
                      {service.title}
                    </LinkTitle>
                  </LinkListItem>
                </Link>
              );
            })}
          </LinkList>
        </Main>
      </Box>
    </Layout>
  );
};

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;

export const query = graphql`
  query OfertaQuery {
    ofertaJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      services {
        content {
          childMarkdownRemark {
            html
          }
        }
        title
        url
      }
    }
  }
`;
